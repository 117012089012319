<template>
  <!-- <transition name="fade"> -->
  <v-dialog v-model="showDialog" persistent max-width="400" class="rounded-xl" scrollable>
    <v-card class="pb-5" rounded="lg">
      <v-card-title class="pt-1 pe-1">
        <v-spacer></v-spacer>
        <CloseBtn size="30" :callback="callbackClose" />
      </v-card-title>
      <v-card-text class="text-center pb-0">
        <v-img width="100" class="mx-auto" :src="selectedItem.imageUrl"
          :class="selectedItem.progress >= selectedItem.milestone ? '' : 'disable-item'"></v-img>
        <div class="mb-0">
          {{ details.name }}
        </div>
        <!-- <div class="text-h6 mb-0 black--text">
          {{ selectedItem.name }}
        </div> -->
        <div class="mb-4 black--text">
          {{ selectedItem.description }}
        </div>
        <v-progress-linear :key="selectedItem.levelKey + 'p'"
          :value="(selectedItem.progress / selectedItem.milestone) * 100" :color="(selectedItem.progress / selectedItem.milestone) < 1 ? 'orange' : 'green'" rounded height="20"
          class="mx-auto" style="max-width: 120px; margin-top: -10px">
          {{
            selectedItem.progress > selectedItem.milestone
              ? selectedItem.milestone + " / " + selectedItem.milestone
              : selectedItem.progress + " / " + selectedItem.milestone
          }}
        </v-progress-linear>
        <div
          class="mt-4 text-caption"
          v-if="selectedItem.AchievementLevelReward?.length > 0"
        >
          {{ $t("model.prop.rewards") }}
        </div>
        <Item
          v-for="reward in selectedItem.AchievementLevelReward"
          :key="reward.key"
          :imageUrl="JSON.parse(reward.Item.images).icon.url"
          :count="reward.quantity"
          :isShowCount="true"
          style="width: 40px;"
          class="mx-auto"
        />
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="1"/>
          <template v-for="(level, index) in details.levels">
            <v-col cols="2" v-if="index > 0" :key="level.levelKey" class="d-flex justify-center">
              <v-btn elevation="0"  @click="onTabChange(level.levelKey)" class="px-0" :color="level.levelKey == selectedItem.levelKey ? 'yellow' : 'transparent'">
              <v-img :src="level.imageUrl" :class="level.progress >= level.milestone ? '' : 'disable-item'" width="40"
                height="40" contain></v-img>
                </v-btn>
            </v-col>
          </template>
          <v-col cols="1"/>
        </v-row>
        <v-divider class="my-2"></v-divider>
      </v-card-text>
      <!-- <v-card flat>
        <v-tabs center-active show-arrows v-model="selectedTab">
          <v-tab v-for="level in details.levels" :key="level.levelKey" class="mx-auto"
            @change="onTabChange(level.levelKey)" active-class="custom-active-tab">
            <v-img :src="level.imageUrl" :class="level.progress >= level.milestone ? '' : 'disable-item'" width="50"
              height="50"></v-img>
          </v-tab>
        </v-tabs>
        <v-divider class="mx-6 my-3"></v-divider>
      </v-card> -->
      <v-btn color="yellow" class="my-4 pl-4 pr-2 mx-auto text-h5" :to="details.actionUrl" large rounded>
        <span>{{ $t("string.go") }}</span>
        <v-img height="40" width="40" contain :src="details.actionIconUrl"></v-img>
      </v-btn>
    </v-card>
  </v-dialog>
  <!-- </transition> -->
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    Item: () =>
      import(
        /* webpackChunkName: "component-mission-reward-item" */ "@/components/shop/Item.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: ["showDialog", "callbackClose", "details"],
  data: () => ({
    selectedItem: {},
    selectedTab: 0,
  }),
  mounted() {
    this.selectedItem =
      this.details.currentLevel.isRewardClaimed && this.details.nextLevel
        ? this.details.nextLevel
        : this.details.currentLevel;

    this.selectedTab = this.details.levels.findIndex(
      (level) => level.levelKey === this.selectedItem.levelKey
    );
  },
  methods: {
    onTabChange(key) {
      this.selectedItem = this.details.levels.find(
        (level) => level.levelKey === key
      );
    },
  },
};
</script>

<style scoped>
.custom-active-tab {
  background-color: #0bad4136;
  transition: background-color 0.3s ease;
}

.disable-item {
  /* filter: grayscale(100%) contrast(50%); */
  opacity: 0.5;
}
</style>
